var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.menuGroupsAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["menuGroupsAsideShow", val]),
      },
    },
    [
      _c("CSidebarClose", {
        nativeOn: {
          click: function ($event) {
            return _vm.$store.commit("toggle", "menuGroupsAsideShow")
          },
        },
      }),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showErrorAlert,
              expression: "showErrorAlert",
            },
          ],
          staticClass: "list-group-accent mt-5",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Error ")]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-danger list-group-item-divider",
              attrs: { href: "#" },
            },
            [
              _c(
                "div",
                { staticClass: "c-avatar float-left mr-2" },
                [
                  _c("CIcon", {
                    staticClass: "c-avatar-img text-danger",
                    attrs: { name: "cil-bug", size: "xl" },
                  }),
                ],
                1
              ),
              _c("div", [_vm._v(_vm._s(_vm.errorAlertMessage))]),
            ]
          ),
        ],
        1
      ),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showErrorAlert,
              expression: "!showErrorAlert",
            },
          ],
          staticClass: "list-group-accent mt-5",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" " + _vm._s(_vm.item.Name) + " ")]
          ),
          typeof _vm.item.id !== "undefined"
            ? _c(
                "CListGroupItem",
                {
                  staticClass:
                    "list-group-item-accent-warning list-group-item-divider",
                  attrs: {
                    to: {
                      name: "Menu Group Childs",
                      params: {
                        mnuExtGrpId: _vm.item.id,
                        menuGroupName: _vm.item.Name,
                      },
                    },
                  },
                },
                [
                  _c("div", [
                    _c("strong", [_vm._v("Childs")]),
                    _vm._v(" Count"),
                  ]),
                  _c(
                    "h4",
                    { staticClass: "text-muted mr-3" },
                    [
                      _c("CIcon", {
                        staticClass: "mr-2",
                        attrs: { name: "cil-arrow-right" },
                      }),
                      _vm._v(
                        _vm._s(_vm.item.menu_ext_group_childs_count) + " "
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-primary list-group-item-divider",
            },
            [
              _c("div", [
                _c("strong", [_vm._v("Linked Menus")]),
                _vm._v(" Count"),
              ]),
              _c(
                "h4",
                { staticClass: "text-muted mr-3" },
                [
                  _c("CIcon", {
                    staticClass: "mr-2",
                    attrs: { name: "cil-arrow-right" },
                  }),
                  _vm._v(_vm._s(_vm.item.menu_linked_ext_group_count) + " "),
                ],
                1
              ),
            ]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-success list-group-item-divider",
            },
            [
              _c("div", [
                _c("strong", [_vm._v("In Order Items")]),
                _vm._v(" Count"),
              ]),
              _c(
                "h4",
                { staticClass: "text-muted mr-3" },
                [
                  _c("CIcon", {
                    staticClass: "mr-2",
                    attrs: { name: "cil-arrow-right" },
                  }),
                  _vm._v(_vm._s(_vm.item.in_order_items_count) + " "),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }